<template>
    <main>
        <h1>Products</h1>
        <ul>
            <Cards :collection="this.products"></Cards>
        </ul>
    </main>
</template>

<script>
    /* eslint-disable */
    import Cards from '@/components/Cards.vue'

    export default {
        components: {
            Cards
        },
        inject: [
            'products',
        ]
    }
</script>

<style>

</style>